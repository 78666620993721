export const user = JSON.parse(localStorage.getItem('user'));
const Token = localStorage.getItem('Authorization')
export const headers = {'Authorization': Token};
export const imageHeader = {"Content-Type": "multipart/form-data", "Authorization": Token}

export const usertype = {
  brand:1,
  influencer: 2,
  admin: 3,
  temp_brand: 4,
  temp_influencer: 5,
  admin_marketing: 6,
  admin_management_support: 7
}

export const campaigntype = {
  open: 1, close: 2
}

export const campaignstatus = {
  plan: 1,
  recruit: 2,
  select: 3,
  register: 4,
  report: 5,
  active: 6,
  close: 7
}

export const snsplatform = {
  xiaohongshu: 1,
  weibo: 2,
  instagram: 3,
  douyin: 4,
  Kuaishou: 5,
  dewu: 6,
  facebook: 7,
  mamabang: 8,
  baobaoshu: 9,
  mamawang: 10,
  yuerwang: 11,
  taobaoguangguang: 12,
  tiktok: 13,
  youtube: 14,
  twitter: 15
}

export const SNS_PLATFORM_ICON = {
  1: 'xiao_ic',
  2: 'weibo_ic',
  3: 'instagram_ic',
  4: 'tiktok_ic',
  5: 'kwai_ic',
  6: 'poizon_ic',
  7: 'facebook_ic',
  8: 'mamabang_ic',
  9: 'baobaoshu_ic',
  10: 'mamawang_ic',
  11: 'yuerwang_ic',
  12: 'taobao_ic',
  13: 'global_tiktok_ic',
  14: 'youtube_ic',
}

export const CampaignIsEndButton = [
  {title: '전체', state: null},
  {title: '모집 종료', state: true},
  {title: '모집중', state: false},
]

export const CampaignIsMatchingButton = [
  {title: '전체 캠페인', state: null},
  {title: '매칭 캠페인', state: 'is_matching'},
  {title: '선정 캠페인', state: 'is_selection'},
  {title: '등록 캠페인', state: 'is_registration'},
  {title: '종료 캠페인', state: 'is_end'},
]

export const PaymentUserTypeButton = [
  {title: '전체', state: null},
  {title: '브랜드', state: 1},
  {title: '인플루언서', state: 2},
]

export const paymentstate = {
  wait: 1,
  complete: 2,
  expired: 3,
  fail: 4,
}

export const mailtargetuser = [
  {name_ko: '모든 회원', vip_state: 'all', target_users_type_id: 1,},
  {name_ko: '모든 일반 회원', vip_state: 'normal', target_users_type_id: 1,},
  {name_ko: '모든 VIP 회원', vip_state: 'vip', target_users_type_id: 1,},
  {name_ko: '브랜드 회원', vip_state: 'all', target_users_type_id: 2,},
  {name_ko: '브랜드 일반 회원', vip_state: 'normal', target_users_type_id: 2,},
  {name_ko: '브랜드 VIP 회원', vip_state: 'vip', target_users_type_id: 2,},
  {name_ko: '인플루언서 회원', vip_state: 'all', target_users_type_id: 3,},
  {name_ko: '인플루언서 일반 회원', vip_state: 'normal', target_users_type_id: 3,},
  {name_ko: '인플루언서 VIP 회원', vip_state: 'vip', target_users_type_id: 3,},
  {name_ko: '단일 회원', vip_state: 'all', target_users_type_id: 4,},
  {name_ko: '수집된 인플루언서', vip_state: 'all', target_users_type_id: 6,},
]


export const keywordpositivetypes = [
  {name_ko: '긍정', id: 1},
  {name_ko: '중립', id: 3},
  {name_ko: '부정', id: 2},
]

export const contentsstudiostatus = [
  {name_ko: '생성중', id: 1},
  {name_ko: '생성완료', id: 2},
]

export const contentsstudiolang = [
  {id: 1, name_ko: '한국어', lang_code: 'ko'},
  {id: 2, name_ko: '일본어', lang_code: 'ja'},
  {id: 3, name_ko: '영어', lang_code: 'en'},
  {id: 4, name_ko: '중국어', lang_code: 'zh-CN'},
]

export const contentsstudioslike = [
  {id: 1, name_ko: '만족'},
  {id: 2, name_ko: '불만족'}
]

export const PaymentStatus = [
  {id: 0, name_ko: '전체'},
  {id: 1, name_ko: '정기결제'},
  {id: 3, name_ko: '업그레이드'},
  {id: 4, name_ko: '다운그레이드'},
  {id: 5, name_ko: '구독해지'}
];

export const PaymentUserLevel = [
  {id: 0, name_ko: '전체'},
  {id: 3, name_ko: 'STARTER'},
  {id: 4, name_ko: 'STANDARD'},
  {id: 5, name_ko: 'PRO'}
];


export const PaymentState = [
  {id: 0, name_ko: '전체'},
  {id: 1, name_ko: '카드결제'},
  {id: 2, name_ko: '카드취소'},
];

export const PaymentSuccess = [
  {id: 0, name_ko: '전체'},
  {id: 1, name_ko: '성공'},
  {id: 2, name_ko: '실패'}
];

export const PAYMENT_STATUS_HISTORY = {
  '1': {name_ko: '정기 결제', name_en: 'first'},
  '2': {name_ko: '정기 결제', name_en: 'recurring'},
  '3': {name_ko: '업그레이드', name_en: 'upgrade'},
  '4': {name_ko: '다운그레이드', name_en: 'downgrade'},
  '5': {name_ko: '구독해지', name_en: 'refund'},
  '6': {name_ko: '기타', name_en: 'etc'},
}

export const KG_CARD_CODE = {
  "01": "외환카드",
  "02": "우리카드",
  "03": "롯데카드",
  "04": "현대카드",
  "06": "국민카드",
  "11": "비씨카드",
  "12": "삼성카드",
  "14": "신한카드",
  "15": "한미카드",
  "16": "NH카드",
  "17": "하나 SK카드",
  "21": "글로벌 VISA",
  "22": "글로벌 MASTER",
  "23": "글로벌 JCB",
  "24": "글로벌 아멕스",
  "25": "글로벌 다이너스",
  "26": "은련카드",
  "91": "네이버포인트",
  "93": "토스머니",
  "94": "SSG머니",
  "96": "엘포인트",
  "97": "카카오머니",
  "98": "페이코 포인트",
}

export const ValidExcel = (file) => {
  return file.name.match(/\.(xlsx)/);
}

export const INVOICE_STATUS = {
  progress: 1,
  payment_request_available: 2,
  payment_request: 3,
  transfer_completed: 4
}