import axios from "axios";
import {ApiWithHeader} from "@static/getApi";

class AxiosRestApi{
  async RestApi ({url, param, headers, type, is_download=false}) {
    let data = {}
    let success = false
    await axios({method: type, url: url, data: param, headers: headers, responseType: is_download && 'blob',})
        .then((response) => {
          data = response.data
          success = true
        })
        .catch((e) => {
          if (e.response) {
            data = e.response.data;
            if (/토큰이 유효하지 않습니다./.test(data.detail)) {
              const param = {};
              const url = `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/account/logout`;
              const logout = ApiWithHeader({param, url, headers});
              if (!logout.error) {
                localStorage.removeItem('user');
                localStorage.removeItem('Authorization');
                window.history.pushState({}, null, '/');
                window.location.reload();
              }
            }
          } else if (e.request) {
            data = {detail: '응답을 받지 못했습니다.'}
          } else {
            data = {detail: e.message}
          }
        })
    return {data, success}
  }
}

export default AxiosRestApi
